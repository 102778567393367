export const VAT_PERMISSABLE_COUNTRIES = ["DE", "CH"];
export const SWITZERLAND_COUNTRY_CODE = 'CH';
export const GERMANY_COUNTRY_CODE = 'DE';
export const SINGLE_STEP_CHECKOUT = 'single_step_checkout';
export const ONE_TIME_PAYMENT = 'one_time_payment';
export const RECURRING_PAYMENT = 'abonement';
export const BREAKDOWN_PAYMENT = 'breakdown_payment';
export const STRIPE = 'stripe';
export const PAYPAL = 'paypal';
export const SEPA = 'sepa';
export const SOFORT = 'sofort';
export const INVOICE = 'invoice';
export const KLARNA = 'klarna';
export const PAYLATER = 'paylater';
export const SEQURA = 'sequra';
export const SEQURA_COUNTRY_CODES = ['ES'];
export const SEQURA_PRODUCT_TYPES = ['digital', 'digital_service', 'electronic_book'];
export const TEST = 'test';
export const YEAR_2018 = '2018';
export const EURO_COUNTRIES = ["AT", "BE", "DE", "ES", "FI", "IE", "IT", "NL", "FR", "GR", "PT"];
export const SETTINGS_TYPES = {
  generic: 'Generic',
  memberspot: 'Memberspot',
  coachy: 'Coachy',
  infusionsoft: 'Infusionsoft',
  klicktipp: 'Klick-Tipp',
  kajabi: 'Kajabi',
  learningsuite: 'LearningSuite',
  umsatz: 'Umsatz',
  hyros: 'Hyros',
  skool: 'Skool'
}
export const SHOWN_PAGES_NUMBER = 4;
export const SERVICE_PRODUCT_TYPES = ['physical_service', 'digital_service'];
