import trim from "lodash/trim";

export function valueAsCurrency(value, currency) {
  let number = isNaN(value) &&  isNaN(Number(value)) ? parseStringToNumber(value) : value;

  if(trim(currency) == '$' || trim(currency).toLocaleUpperCase() == "USD") {

    return Number(number).toLocaleString(document.body.dataset.numbersLocale || 'en-DE', {
      style: 'currency',
      currency: 'USD'
    });
  }

  if(trim(currency) == '€' || trim(currency).toLocaleUpperCase() == "EUR") {
    return Number(number).toLocaleString(document.body.dataset.numbersLocale || 'en-DE', {
      style: 'currency',
      currency: 'EUR'
    });
  }

  return `${formatMoney(value)} ${trim(currency)}`;
}

export function parseStringToNumber(raw_str) {
  let str_num = raw_str || "";
  let decimal_point = '.';

  str_num = str_num.replace(/^(-) | [^0-9$.,]/g, '');

  if(str_num.indexOf(',') > str_num.indexOf('.')) {
    str_num = str_num.replace(/\./g, ''); // DE format 23.999,00
    decimal_point = ',';
  }

  if((str_num.match(new RegExp("\\" + decimal_point, "g")) || []).length > 1) {
    decimal_point="";
  }

  if(decimal_point !== "" &&
    (str_num.length - str_num.indexOf(decimal_point) - 1 == 3) &&
    str_num.indexOf("0" + decimal_point)!==0) {
    decimal_point = "";
  }

  str_num = str_num.replace(new RegExp("^(-) | [^0-9$" + decimal_point + "]","g"), "");

  str_num = str_num.replace(new RegExp("\\" + (decimal_point === "," ? "." : ","),"g"), "");

  str_num = !!decimal_point ? str_num.replace(new RegExp("\\" + decimal_point, "g"), ".") : str_num;

  return parseFloat(str_num);
}

export function formatMoney(value) {
  let result = null;
  let temp = null;

  if (typeof value == 'number') {
    result = value.toFixed(2);
  } else {
    temp = Number(value);
    result = !Number.isNaN(temp) ? temp.toFixed(2) : Number(value);
  }

  return formatNumber(result, true);
}

export function formatNumber(value, keepFractionDigits = false) {
  let number = isNaN(value) && isNaN(Number(value)) ? parseStringToNumber(value) : value;

  let props = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  if(!keepFractionDigits && number % 1 === 0) {
    props = {};
  }

  return Number(number).toLocaleString(document.body.dataset.numbersLocale || 'en-DE', props);
}

export function valueAsPercentage(value) {
  let currency_value = Number(value).toLocaleString(document.body.dataset.numbersLocale || 'en-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return `${currency_value} %`;
}

export function chartCurrency(currency_sign, value) {
  let currency_value = Number(value).toLocaleString(document.body.dataset.numbersLocale || 'en-DE', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  return `${currency_sign} ${currency_value}`;
}

export function currencyFormat(value) {
  return Number(value).toLocaleString(document.body.dataset.numbersLocale || 'en-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function toFloatOrZero(value) {
  const converted = _.isString(value) ? parseStringToNumber(value) : value;

  return parseFloat(converted) || 0;
}
