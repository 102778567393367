export default {
  identifyVisitorInterval: null,
  currentEmail: null,

  // Shop visit →
  // after the customer selects country
  trackShopVisit(shop_id, country) {
    if(!localStorage.getItem("firstVisitTime")) {
      localStorage.setItem("firstVisitTime", new Date().getTime());
    }
    document.dispatchEvent(new CustomEvent(
      "shop.visit", {
      detail: {
        shop_id: shop_id,
        country: country
      },
    }));
  },

  // Shop product viewed →
  // after the customer clicks on the product to see the preview
  trackProductView(shop_id, product_id) {
    document.dispatchEvent(new CustomEvent(
      "shop.product.view", {
      detail: {
        shop_id: shop_id,
        product_id: product_id
      },
    }));
  },

  // Shop product added to cart →
  // after the customer clicks add to shop by popup button or icon in the list
  trackProductAdded(shop_id, product_id) {
    document.dispatchEvent(new CustomEvent(
      "shop.product.added", {
      detail: {
        shop_id: shop_id,
        product_id: product_id
      },
    }));
  },

  // Shop product searched →
  // on the search
  trackProductSearched(shop_id, search_term) {
    document.dispatchEvent(new CustomEvent(
      "shop.product.searched", {
      detail: {
        shop_id: shop_id,
        search_term: search_term
      },
    }));
  },

  // Shop checkout proceeded →
  // when Go to Checkout button clicked
  trackCheckoutProceeded(shop_id) {
    document.dispatchEvent(new CustomEvent(
      "shop.checkout", {
      detail: {
        shop_id: shop_id
      },
    }));
  },

  // Shop checkout →
  // when Checkout button clicked
  trackCheckoutOrdered(shop_id) {
    let firstVisitTime = localStorage.getItem("firstVisitTime");
    let lastVisitTime = new Date().getTime();

    localStorage.removeItem("firstVisitTime");

    document.dispatchEvent(new CustomEvent(
      "shop.checkout.ordered", {
      detail: {
        shop_id: shop_id,
        time_spent: Math.ceil((lastVisitTime - firstVisitTime)/1000)
      },
    }));
  },

  // Shop Checkout, email revealed →
  // When visitor entered email
  identifyVisitor(email) {
    this.currentEmail = email;

    if(this.identifyVisitorInterval) {
      clearTimeout(this.identifyVisitorInterval);
      this.identifyVisitorInterval = null;
    }
    this.identifyVisitorInterval = setTimeout(() => {
      document.dispatchEvent(new CustomEvent(
        "shop.identify", {
        detail:  {
          'email' : email
        },
      }));
    }, 1000);
  },
}
