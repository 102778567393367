import Vue from 'vue';
import VueResource from 'vue-resource';
import 'whatwg-fetch';
import * as constants from 'shared/constants';
import { valueAsCurrency, parseStringToNumber, formatNumber } from 'shared/value_formats';
import ShopTracking from 'shared/shop/mixins/shop_tracking';
import vSelect from 'vue-select';

Vue.use(VueResource);

export default {
  components: {
    'v-select': vSelect
  },
  data() {
    return {
      categories: [],
      currentCategory: '',
      checkoutEditMode: false,
      categoriesVisible: false,
      categoriesEnabled: false,
      totalProductsInBucket: 0,
      shopHasBestsellers: false,
      previousPricingParams: null,
      showProductModal: false,
      showProductCategoriesModal: false,
      showLanguageDropdown: false,
      shoppingCartPopupVisible: false,
      showAddInfoModal: false,
      showErrorInfoModal: false,
      errorMessage: null,
      showRemoveInfoModal: false,
      showNotAvailableModal: false,
      curentAddon: null,
      addingProductToCart: false,
      cartUUID: null,
      shoppingCart: null,
      countDownTimerInterval: null,
      countDownTimerData: null,
      countDownTimerEndDate: null,
      followingCountryCode: null,
      followingCheckoutLocale: null,
      isRecalculating: false,
      XCSRFTokenHeaders: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'content-type': 'application/json'
      },
      searchTerm: null,
      filterTerm: null,
      addonsWithBundles: [],
      selectedSorting: '',
      sortingFilters: [
        { label: I18n.t('js.shop.sortings.status'), value: "" },
        { label: I18n.t('js.shop.sortings.name'), value: "name" },
        { label: I18n.t('js.shop.sortings.price'), value: "price" }
      ],
      showSelectCountryModal: false,
    }
  },
  mounted() {
    this.addonsWithBundles = this.addons.concat(this.bundles)
    this.showCountryModal()

    const categoriesPresent = !!this.product.addon_categories;

    this.categoriesVisible = categoriesPresent;
    this.categoriesEnabled = categoriesPresent;

    this.shopHasBestsellers = this.shopSetting.bestseller_ids && this.shopSetting.bestseller_ids.length;

    this.categories = this.processCategories(this.product.addon_categories);
    this.getPricingData();
    setCartRootUrl(this.shoppingCartRootUrl);
    initializeCartSilent(this.product.slug, this.checkoutEditMode, this.getCartDetails);
    /* Disable timer for now - COP-12702
    if(this.withDiscount() && this.shopSetting.countdown_timer_enabled) {
      this.initCountDownTimer()
    } // */

    this.showNotAvailableModal = !this.product.is_active;
    if (localStorage.country_code) {
      this.orderForm.country_code = localStorage.country_code;
      this.followingCountryCode = localStorage.country_code;
    } else {
      this.followingCountryCode = this.orderForm.country_code;
    }
    this.followingCheckoutLocale = this.locale;

    $("#shop-preloader").animate({opacity:0}, 500, () => {
      $("#shop-preloader").css("display", "none");
    });
    $("#shop-form").css("display", "");
    this.enableScrollTracking();

    if (this.activity == "language_changed") {
      this.hideCountryModal();
    }

    ShopTracking.trackShopVisit(this.product.slug, this.countryName());
  },
  methods: {
    toggleLanguageDropdown() {
      this.showLanguageDropdown = !this.showLanguageDropdown;
    },
    hideLanguageDropdown(e) {
      if (!document.getElementById('languages-dropdown').contains(e.target)) {
        this.showLanguageDropdown = false

        document.removeEventListener('click', this.hideLanguageDropdown);
      }
    },
    priceFormatter(price) {
      return parseFloat(price).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    showCountryModal() {
      $('body').addClass('modal-open')
      this.showSelectCountryModal = true
    },
    hideCountryModal() {
      $('body').removeClass('modal-open')
      this.showSelectCountryModal = false
    },
    enableScrollTracking() {
      // sticky header tracking
      $(window).on('scroll', (e) => {
        if($(window).scrollTop() > 20) {
          $('body').addClass('header-detached');
        } else {
          $('body').removeClass('header-detached');
        }
      });
    },
    parseInternationalFloat: function(raw_str) {
      if(typeof raw_str === "number") {
        return raw_str;
      }

      return parseStringToNumber(raw_str);
    },
    getThumbnailUrl(imageUrl) {
      return imageUrl.replace('.s3.', '-resized.s3.');
    },
    initCountDownTimer() {
      let vm = this;

      if (this.promocode) {
        vm.countDownTimerEndDate = new Date(this.promocode.end_date).getTime();

        const now = new Date().getTime();

        if(vm.countDownTimerEndDate < now) {
          return;
        }

        vm.countDownTimerInterval = setInterval(function() {
          const now = new Date().getTime();

          var diff = vm.countDownTimerEndDate - now;

          Vue.set(vm, 'countDownTimerData', {
            'days': Math.floor(diff / (1000 * 60 * 60 * 24)),
            'hours': Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            'minutes': Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
            'seconds': Math.floor((diff % (1000 * 60)) / 1000)
          })

          if (diff < 0) {
            clearInterval(vm.countDownTimerInterval);
            vm.shopSetting.discount_enabled = false;
            vm.shopSetting.countdown_timer_enabled = false;
            vm.shopSetting.promocode_id = '';
            vm.countDownTimerData = null;
            vm.getPricingData();
          }
        }, 1000);
      }
    },
    showDetailsModal(addon) {
      $('body').addClass('modal-open');
      this.curentAddon = addon;
      this.showProductModal = true;
    },
    hideDetailsModal() {
      $('body').removeClass('modal-open');
      this.showProductModal = false
    },
    formatNumber: formatNumber,
    asCurrency(value, currency) {
      return valueAsCurrency(value, currency);
    },
    t(key) {
      return I18n.t(key, { scope: 'js.checkout' });
    },
    formattedAmount(amount) {
      if (isNaN(parseFloat(amount))) { return 0; }
      return parseFloat(amount).toFixed(2).replace('.', ',');
    },
    addressParamsForPricingData() {
      return '{}';
    },
    buildGetPricingDataParams() {
      let productParams = JSON.stringify({
        promocode_id: this.withDiscount() ? this.shopSetting.promocode_id || '' : '',
        product_slug: this.product.slug,
        payment_plan_id: this.currentPaymentPlan.id,
        quantity: 0
      });

      let addonsParams = JSON.stringify(this.addonsWithBundles.map(a => {
        return { addon_id: a.id, payment_plan_id: a.plan_id, quantity: a.quantity }
      }));

      let addressParams = this.addressParamsForPricingData();

      let params = {
        country_code: this.orderForm.country_code,
        is_private_person: true,
        vat_number: '',
        product_info: productParams,
        addons_info: addonsParams,
        address: addressParams
      };

      return params;
    },
    buildUrlParams(params) {
      let urlString = Object.keys(params).map(function(k) {
        return k + "=" + encodeURIComponent(params[k]);
      }).join('&');

      return "?" + urlString;
    },
    toggleShowHideCategories() {
      this.categoriesVisible = !this.categoriesVisible;
    },
    getCartDetails() {
      let vm = this;

      this.cartUUID = cartUUID();

      const url =  `${this.shoppingCartRootUrl}/shopping_carts/${cartUUID()}/details`;

      this.$http.get(url, {
        method: 'get',
        headers: this.XCSRFTokenHeaders
      }).then(function(response) {
        // Convert to JSON
        return response.json();
      }).then(function(json) {
        // console.log(json);
        if(json.addons_qty) {
          vm.totalProductsInBucket = json.addons_qty;
        }
        if(json.shopping_cart) {
          vm.shoppingCart = json.shopping_cart;
        }
      }).catch(function(err) {
        console.log("Error: getCartDetails", err);
      });
    },
    clearCart() {
      let vm = this;

      this.cartUUID = cartUUID();

      const url =  `${this.shoppingCartRootUrl}/shopping_carts/${cartUUID()}/clear`;

      this.$http.get(url, {
        method: 'get',
        headers: this.XCSRFTokenHeaders
      }).then(function(response) {
        // Convert to JSON
        return response.json();
      }).then(function(json) {
        // console.log(json);
        vm.totalProductsInBucket = 0;
        if(json.shopping_cart) {
          vm.shoppingCart = json.shopping_cart;
        }
      }).catch(function(err) {
        console.log("Error: clearCart", err);
      });
    },
    hideInfoModal() {
      this.showAddInfoModal = false;
      this.showRemoveInfoModal = false;
      this.showErrorInfoModal = false;
      this.errorMessage = null;
    },
    removeFromToCart(addon) {
      let vm = this;

      const params = {
        product_slug: this.product.slug,
        addon_slug: addon.source_product_slug,
        quantity: 1
      };

      const query = Object.keys(params).map(k => `${k}=${params[k]}`).join('&');

      this.addingProductToCart = true;

      this.$http.get(`${this.shoppingCartRootUrl}/shopping_carts/${cartUUID()}/remove?${query}`, {
        method: 'get',
        headers: this.XCSRFTokenHeaders
      }).then(function(response) {
        // Convert to JSON
        return response.json();
      }).then(function(json) {
        let amount = 0;
        for (let key in json.shopping_cart) {
          amount += json.shopping_cart[key];
        }
        if(json.shopping_cart) {
          vm.shoppingCart = json.shopping_cart;
        }
        vm.totalProductsInBucket = amount;
        vm.showProductModal = false;
        vm.addingProductToCart = false;
        vm.showRemoveInfoModal = true;
        setTimeout(() => {
          vm.hideInfoModal()
        }, 3000);
      }).catch(function(err) {
        console.log("Error: addToCart", err);
        vm.addingProductToCart = false;
        if (err.status == 404) {
          toastr.error(I18n.t('js.failed'));
          location.reload();
        }
      });
    },
    addToCart(addon, highlight = false) {
      if (this.isNotAvailableCountry(addon)) { return; };
      let vm = this;

      const params = {
        product_slug: this.product.slug,
        addon_slug: addon.source_product_slug,
        quantity: 1
      };

      const query = Object.keys(params).map(k => `${k}=${params[k]}`).join('&');

      this.addingProductToCart = true;

      this.$http.get(`${this.shoppingCartRootUrl}/shopping_carts/${cartUUID()}/add?${query}`, {
        method: 'get',
        headers: this.XCSRFTokenHeaders
      }).then(function(response) {
        // Convert to JSON
        return response.json();
      }).then(function(json) {
        let amount = 0;
        for (let key in json.shopping_cart) {
          amount += json.shopping_cart[key];
        }
        if(json.shopping_cart) {
          vm.shoppingCart = json.shopping_cart;
        }
        vm.totalProductsInBucket = amount;
        vm.showProductModal = false;
        document.body.classList.remove('modal-open');
        vm.addingProductToCart = false;
        if(json.message) {
          vm.errorMessage = json.message;
          vm.showErrorInfoModal = true;
        } else {
          vm.showAddInfoModal = true;
        }
        setTimeout(() => {
          ShopTracking.trackProductAdded(
            vm.product.slug,
            addon.source_product_slug);
          vm.hideInfoModal()
        }, 3000);

        if(highlight && !json.message) {
          const product_ovelay = $(`.shop-product-${addon.id}-overlay`)
          product_ovelay.css({display: 'flex', opacity: 1})
          .delay(1000)
          .animate({opacity: 0 }, 200, "linear", () => {
            product_ovelay.css('display', 'none');
          });
        }
      }).catch(function(err) {
        console.log("Error: addToCart", err);
        vm.addingProductToCart = false;

        if (err.status == 404) {
          toastr.error(I18n.t('js.failed'));
          location.reload();
        }
      });
    },
    quickAddToCart(addon) {
      if(this.addingProductToCart) { return; }
      this.addToCart(addon, true);
    },
    processCategories(categoriesString) {
      if(!categoriesString || !categoriesString.split) {
        return [];
      }

      return categoriesString.split(",").map(function(item) {
        return item.trim();
      }).filter(category => category.trim() != '');
    },
    getPricingData(callbackFn = undefined){
      let vm = this;

      let pricingParams = JSON.stringify(vm.buildGetPricingDataParams());

      if(vm.previousPricingParams == pricingParams) {
        return;
      } else {
        Vue.set(vm, 'previousPricingParams', pricingParams);
        setTimeout(
          () => {
            Vue.set(vm, 'previousPricingParams', null);
          }, 1000
        );
      }

      Vue.set(vm, 'isRecalculating', true);

      fetch(vm.pricingUrl, {
        method: 'post',
        headers: vm.XCSRFTokenHeaders,
        body: pricingParams
      }).then(function(response) {
        // Convert to JSON
        return response.json();
      }).then(function(json) {
        vm.addons.concat(vm.bundles).forEach(addon => {
          let addonInfo = json.addons_info.find(item => item.product_slug == addon.source_product_slug);
          let discount = (addonInfo.discount_total_gross_amount || 0)

          Vue.set(addon, 'bruttoPrice', addonInfo.brutto_price);
          Vue.set(addon, 'nettoPrice', addonInfo.netto_price);
          Vue.set(addon, 'categories', vm.processCategories(addonInfo.categories))
          Vue.set(addon, 'vatAmount', addonInfo.vat_amount);
          Vue.set(addon, 'shippingNettoAmount', addonInfo.shipping_netto_amount);
          Vue.set(addon, 'shippingBruttoAmount', addonInfo.shipping_brutto_amount);
          Vue.set(addon, 'shippingVatAmount', addonInfo.shipping_vat_amount);
          Vue.set(addon, 'vatPercent', addonInfo.vat_percent);
          Vue.set(addon, 'bruttoPricePreview', !!addon.trial_days ? vm.orderForm.default_trial_price : addon.bruttoPrice);
          Vue.set(addon, 'nettoPricePreview', !!addon.trial_days ? vm.orderForm.default_trial_price : addon.nettoPrice);
          Vue.set(addon, 'nettoPricePreviewWithDiscount', !!addon.trial_days ? vm.orderForm.default_trial_price : addon.nettoPrice - addonInfo.discount_net_amount);
          Vue.set(addon, 'vatAmountPreview', !!addon.trial_days ? vm.orderForm.default_trial_price : addon.vatAmount);
          Vue.set(addon, 'vatAmountPreviewWithDiscount', !!addon.trial_days ? vm.orderForm.default_trial_price : addon.vatAmount - addonInfo.discount_vat_amount);
          Vue.set(addon, 'bruttoPriceWithDiscount', addon.bruttoPrice - discount);
          Vue.set(addon, 'bruttoPriceWithDiscountPreview', !!addon.trial_days ? vm.orderForm.default_trial_price : addon.bruttoPrice - discount);
          Vue.set(addon, 'discountGross', discount);
          Vue.set(addon, 'discountNet', addonInfo.discount_net_amount);
          Vue.set(addon, 'nextDiscountNet', addonInfo.discount_next_net_amount);
          Vue.set(addon, 'nextDiscountGross', addonInfo.discount_next_gross_amount);
          Vue.set(addon, 'discountGrossPreview', !!addon.trial_days ? vm.orderForm.default_trial_price : -discount);
          Vue.set(addon, 'saleable', addonInfo.saleable);

          if(addonInfo.in_stock_left != null) {
            Vue.set(addon, 'inStockLeft', addonInfo.in_stock_left);
            Vue.set(addon, 'isStockVisible', addonInfo.is_stock_visible);
          } else {
            Vue.set(addon, 'inStockLeft', null);
            Vue.set(addon, 'isStockVisible', null);
          }
          if(addon.plan_type === constants.ONE_TIME_PAYMENT) {
            Vue.set(addon, 'plan_short_description',` ${vm.formattedAmount(addon.nettoPricePreview)} ${vm.currencySign}`);
          }
        });
        Vue.set(vm, 'isRecalculating', false);

        if(callbackFn != undefined && typeof callbackFn == 'function') {
          callbackFn();
        }

      }).catch(function(err) {
        console.log("Error: getPricingData", err);
        Vue.set(vm, 'isRecalculating', false);
      });
    },
    // START: Price details function
    checkPlanDescription: function(plan, addon = null) {
      if (plan.plan_type == constants.RECURRING_PAYMENT) {
        return this.abonementShortDescription(plan, addon);
      } else if (plan.plan_type == constants.BREAKDOWN_PAYMENT) {
        return this.breakdownShortDescription(plan, addon);
      } else {
        return plan.description || '';
      }
    },
    abonementShortDescription: function (plan, addon) {
      let result = [];
      let vm = addon ? addon : this;
      let firstDiscount = this.withDiscount() && this.orderForm.has_net_price ? this.parseInternationalFloat(vm.discountNet) : this.parseInternationalFloat(vm.discountGross);
      let nextDiscount = this.withDiscount() && this.orderForm.has_net_price ? this.parseInternationalFloat(vm.nextDiscountNet) : this.parseInternationalFloat(vm.nextDiscountGross);
      let firstPayment = this.formattedAmount(this.parseInternationalFloat(plan.first_payment) - firstDiscount);

      this.pushFirstPaymentTitle(
        result, 'js.decorators.plan_info_helpers.first_payment',
        (firstDiscount ? `<s>${plan.first_payment}</s> ${firstPayment}` : firstPayment)
      )

      let secondPayment = this.formattedAmount(this.parseInternationalFloat(plan.next_payments) - nextDiscount);
      if(plan.second_payment_in) {
        this.pushSecondPaymentInTitle(
          result, 'js.decorators.plan_info_helpers.second_payment_in', plan,
          (firstDiscount ? `<s>${plan.next_payments}</s> ${secondPayment}` : secondPayment)
        )
      }
      result.push(
        I18n.t(
          'js.decorators.plan_info_helpers.then_next_payments', {
            next_payments: (firstDiscount ? `<s>${plan.next_payments}</s> ${secondPayment}` : secondPayment),
            plan_frequency_abbreviation: this.planFrequency(plan.frequency),
            currency_sign: this.currencySign
          }
        )
      )
      if (this.isShippable(plan)) {
        this.pushShippableTitle(result, plan)
      }
      return result.join(', ')
    },
    breakdownShortDescription: function(plan, addon) {
      let result = [];
      let vm = addon ? addon : this;
      let firstDiscount = this.withDiscount() && this.orderForm.has_net_price ? this.parseInternationalFloat(vm.discountNet) : this.parseInternationalFloat(vm.discountGross);
      let nextDiscount = this.withDiscount() && this.orderForm.has_net_price ? this.parseInternationalFloat(vm.nextDiscountNet) : this.parseInternationalFloat(vm.nextDiscountGross);
      let firstPayment = this.parseInternationalFloat(plan.first_payment) - firstDiscount;

      this.pushFirstPaymentTitle(
        result, 'js.decorators.plan_info_helpers.first_rate',
        (firstDiscount ? `<s>${plan.first_payment}</s> ${this.formattedAmount(firstPayment)}` : this.formattedAmount(firstPayment))
      )

      let secondPayment = this.formattedAmount(this.parseInternationalFloat(plan.next_payments) - nextDiscount);
      if(plan.second_payment_in) {
        this.pushSecondPaymentInTitle(
          result, 'js.decorators.plan_info_helpers.second_payment_in', plan,
          (nextDiscount ? `<s>${plan.next_payments}</s> ${secondPayment}` : secondPayment)
        )
      }

      let paymentsQtty = plan.second_payment_in ? plan.payments_count - 1 : plan.payments_count;
      let nextPayments = this.parseInternationalFloat(plan.next_payments) - nextDiscount;
      if (paymentsQtty > 0) {
        result.push(
          I18n.t(
            'js.decorators.plan_info_helpers.then_n_times_next_payments', {
              payments_qtty: paymentsQtty,
              next_payments: (nextDiscount ? `<s>${plan.next_payments}</s> ${this.formattedAmount(nextPayments)}` : this.formattedAmount(nextPayments)),
              plan_frequency_abbreviation: this.planFrequency(plan.frequency),
              currency_sign: this.currencySign
            }
          )
        )
      }

      let totalBreakdown = this.parseInternationalFloat(plan.first_payment) + this.parseInternationalFloat(plan.next_payments) * paymentsQtty;
      result.push(
        I18n.t(
          'js.decorators.plan_info_helpers.total_breakdown', {
            breakdown_sum: (firstDiscount ? `<s>${this.formattedAmount(totalBreakdown)}</s> ${this.formattedAmount(this.parseInternationalFloat(firstPayment) + (this.parseInternationalFloat(nextPayments) * paymentsQtty))}` : this.formattedAmount(totalBreakdown)),
            currency_sign: this.currencySign
          }
        )
      )

      if (this.isShippable(plan)) {
        this.pushShippableTitle(result, plan)
      }
      return result.join(', ');
    },
    getAddonTotalValue: function(addon, type) {
      return valueAsCurrency(parseFloat(addon[type]), this.currencySign);
    },
    withDiscount: function () {
      return  this.shopSetting.discount_enabled && this.promocode && this.promocode.status == 'active' && this.shopSetting.promocode_id;
    },
    addonDiscountTrialDetails: function (addon) {
      const discount_amount = this.parseInternationalFloat(this.shopSetting.discount_amount);
      const discount_percent = this.parseInternationalFloat(this.shopSetting.discount_percent);

      if (discount_amount == 0 && discount_percent > 0)  {
        return discount_percent + '% (-' + addon.discountGross + ' ' + this.currencySign + ') ';
      }
      if (discount_amount > 0 && discount_percent == 0)  {
        return discount_amount + ' ' + this.currencySign + '(' + addon.discountGross + ' ' + this.currencySign + ') ';
      }
      if (discount_amount > 0 && discount_percent > 0)  {
        return discount_percent + '% + ' + discount_amount + ' ' + this.currencySign + '(' + addon.discountGross + ' ' + this.currencySign + ') ';
      }
    },
    pushFirstPaymentTitle: function(result, localeName, payment) {
      return result.push(
        I18n.t(localeName, {
          currency_sign: this.currencySign,
          first_payment: payment
        })
      )
    },
    pushSecondPaymentInTitle: function(result, localeName, plan, payment) {
      return result.push(
        I18n.t(localeName, {
            second_payment_in: plan.second_payment_in,
            days: this.planDays(plan.second_payment_in),
            next_payments: payment,
            currency_sign: this.currencySign
          }
        )
      )
    },
    pushShippableTitle: function(result, plan) {
      const shipping_description_type = plan.plan_type === constants.ONE_TIME_PAYMENT || plan.charge_shipping_once ? 'one_time_shipping' : 'recurring_shipping';

      return result.push(
        I18n.t(
          `js.decorators.plan_info_helpers.${shipping_description_type}`, {
            shipping_price: this.formattedAmount(parseFloat(plan.shipping_price)),
            currency_sign: this.currencySign
          }
        )
      )
    },
    isShippable: function(plan) {
      return parseFloat(plan.shipping_price) > 0.0;
    },
    planFrequency: function(frequency) {
      return I18n.t(`js.decorators.plan_info_helpers.plan_frequency_abbreviation.${frequency}`)
    },
    planDays: function(days) {
      return I18n.t(`js.decorators.plan_info_helpers.pluralize_days.${this.maybePluralize(days, 'day')}`)
    },
    maybePluralize: function(count, noun, suffix = 's') {
      return `${noun}${count !== 1 ? suffix : ''}`;
    },
    onSearch(searchTerm) {
      this.filterTerm = searchTerm;

      if(this.filterTerm) {
        ShopTracking.trackProductSearched(this.product.slug, this.filterTerm);
      }
    },
    sortByStatus(a, b) {
      let vm = this;
      if(vm.selectedSorting == "price") {
        return parseFloat(a.amount) - parseFloat(b.amount);
      } else if(vm.selectedSorting == "name") {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      } else {
        return 0;
      }
    },
    isNotAvailableCountry(addon) {
      if(addon.countries === 'none') { return true; }
      if(addon.saleable === false) { return true; }

      return addon.countries.length > 0 && !addon.countries.includes(this.orderForm.country_code) && this.addonCountriesDontContainCountryStates(addon);
    },
    addonCountriesDontContainCountryStates(addon){
      return !addon.countries.some((country) => country.includes(this.orderForm.country_code));
    },
    countryRestrictionMessage() {
      return I18n.t('js.checkout.country_restriction_message', { country: this.countryName() })
    },
    countryName(country_code = undefined) {
      let countryCode;

      if(country_code !== undefined) {
        countryCode = country_code;
      } else {
        countryCode = localStorage.country_code ? localStorage.country_code : this.orderForm.country_code;
      }

      return this.localizedCountries.find(country =>  {
        return country.code.toLowerCase() == countryCode.toLowerCase();
      }).name
    },
    selectedCountryFlag: function(country_code = undefined) {
      let countryCode;

      if(country_code !== undefined) {
        countryCode = country_code;
      } else {
        countryCode = localStorage.country_code ? localStorage.country_code : this.orderForm.country_code;
      }

      return this.countryFlagUrl(countryCode.toLowerCase());
    },
    countryFlagUrl(countryCode) {
      return `https://s3.eu-central-1.amazonaws.com/public-prod-copecart.com/flags/1x1/${countryCode.toLowerCase()}.png`;
    },
    onSelectCountryConfirm(){
      $('body').removeClass('modal-open');
      this.selectedCountry = this.followingCountryCode;
      this.orderForm.country_code = this.followingCountryCode;
      localStorage.country_code = this.followingCountryCode;
      if (this.totalProductsInBucket > 0) {
        this.clearCart();
      }

      this.getPricingData(()=>{
        if(this.showSelectCountryModal) {
          this.showSelectCountryModal = false;
        }
      });
    },
    countryCodeChange(selectedCountry) {
      this.followingCountryCode = selectedCountry.code;
    },
    checkoutLocaleChange(selectedLocale) {
      if (this.followingCheckoutLocale.code === selectedLocale.code) {
        return
      }

      document.cookie = `locale_eu=${selectedLocale.code}; path=/`
      location.reload();
    },
    // END: Price details function
  },
  computed: {
    formattedDiscountPertcent: function() {
      return parseStringToNumber(this.shopSetting.discount_percent)
    },
    checkoutUrl() {
      return `${this.shoppingCartRootUrl}/shopping_carts/${this.cartUUID}/show_cart?product_id=${this.product.slug}&country_code=${this.orderForm.country_code}`;
    },
    bestsellersAddons() {
      let vm = this;

      if(!this.shopHasBestsellers) {
        return [];
      }

      return this.addons.filter(a => {
        return (this.currentCategory == '' || a.categories.includes(this.currentCategory)) && vm.shopSetting.bestseller_ids.includes(a.id);
      });
    },
    hasSearchTerm() {
      return !!this.filterTerm;
    },
    commonAddons() {
      let vm = this;

      if (this.hasSearchTerm) {
        const term = vm.filterTerm.toLowerCase();

        return vm.addons.filter((a) => {
          return (vm.currentCategory == '' || a.categories.includes(vm.currentCategory)) &&
            a.name.toLowerCase().includes(term)
        }).sort(vm.sortByStatus);
      } else {
        return this.addons.filter(a => {
          return vm.currentCategory == '' || a.categories.includes(vm.currentCategory);
        }).sort(vm.sortByStatus);
      }
    },
    hasBestsellers() {
      if(!this.shopHasBestsellers) {
        return false;
      }

      return this.bestsellersAddons.length;
    }
  },
  watch: {
    "orderForm.country_code"(newVal, oldVal) {
      if(oldVal != newVal) {
        ShopTracking.trackShopVisit(this.product.slug, this.countryName());
      }
    },
    showProductModal(newVal) {
      if(!!newVal) {
        ShopTracking.trackProductView(
          this.product.slug,
          this.curentAddon.source_product_slug);
      }
    },
    showLanguageDropdown() {
      if (this.showLanguageDropdown) {
        document.addEventListener('click', this.hideLanguageDropdown);
      }
    }
  }
}
